import "./App.css";
import { useEffect, useState } from "react";

import tick from "./img/tick.png";

const BACKEND_URL_BASE = "https://jube-event-back.herokuapp.com";

const Checkbox = ({ text, checked, onClick, updating }) => (
  <div
    onClick={onClick}
    className={checked ? "checkbox checkbox--checked" : "checkbox"}
  >
    <div className={"checkbox--box"}>
      {checked ? (
        <img alt="A check mark" className={"checkbox--tick"} src={tick} />
      ) : null}

      {updating ? (
          <div className="spinner">Updating...</div>
      ) : null}
    </div>

    <p className={"checkbox--text"}>{text}</p>
  </div>
);

function App() {
  const [name, setName] = useState(undefined);
  const [inviteId, setInviteId] = useState(undefined);
  const [attending, setAttending] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [updatingAttending, setUpdatingAttending] = useState(false);
  const [updatingNotAttending, setUpdatingNotAttending] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const inviteId = searchParams.get("inviteId");

    fetch(`${BACKEND_URL_BASE}/participants/${inviteId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }

        throw new Error("Unable to get invite status");
      })
      .then((json) => {
        setName(json.name);
        setAttending(json.attending);
        setInviteId(json.code);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div className="loading-container">
        <div className="spinner spinner--orange spinner--big">Updating...</div>
    </div>;
  }

  return (
    <div className={"content"}>
      <p className={"pre-title"}>Du er</p>
      <p className={"title"}>invitert</p>

      <div className={"meta"}>
        <div>
          <p className={"meta-title"}>Hvor</p>
          <p className={"meta-text"}>Hans Nordahls gate 84</p>
        </div>

        <div>
          <p className={"meta-title"}>Når</p>
          <p className={"meta-text"}>5. november kl. 18:00</p>
        </div>
      </div>

      <div className={"description"}>
        <p>Hei {name}!</p>
        <p>
          Du er herved invitert til allehelgensfest hos Julian og Benedicte!
        </p>
        <p>Ta med egen drikke og ev. snacks/kaker du vil dele med andre.</p>
        <p>
          Kle deg gjerne ut hvis du vil, men du må ikke. RSVP under her innen
          29. oktober
        </p>
      </div>

      <div>
        <Checkbox
          updating={updatingAttending}
          text={"Jeg kommer!"}
          checked={attending}
          onClick={() => {
            setUpdatingAttending(true);

            fetch(`${BACKEND_URL_BASE}/participants/${inviteId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ attending: true }),
            }).then((response) => {
              setUpdatingAttending(false);

              if (response.ok) {
                setAttending(true);
                return;
              }

              throw new Error("Unable to respond");
            });
          }}
        />
        <Checkbox
          updating={updatingNotAttending}
          text={"Kan dessverre ikke..."}
          checked={attending === false}
          onClick={() => {
            setUpdatingNotAttending(true);

            fetch(`${BACKEND_URL_BASE}/participants/${inviteId}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ attending: false }),
            }).then((response) => {
              setUpdatingNotAttending(false);

              if (response.ok) {
                setAttending(false);
                return;
              }

              throw new Error("Unable to respond");
            });
          }}
        />
      </div>
    </div>
  );
}

export default App;
